import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// const $loader = document.querySelector(".loader");
// const $openText = $loader.querySelector("span");
const $frame = document.body;

// window.addEventListener("DOMContentLoaded", () => {
//   $frame.classList.add("scroll-lock");
//   $loader.classList.add("on-load");

//   window.addEventListener("load", () => {
//     setTimeout(() => {
//       $frame.classList.remove("scroll-lock");
//       $loader.classList.remove("on-load");
//     }, 1250);

//     setTimeout(() => {
//       $loader.style.display = "none";
//     }, 1450);
//   });

var menuTriggers = document.querySelectorAll(".menu-trigger");
// var menuTriggerCross = document.querySelector(".menu-trigger");
var menu = document.querySelector(".burger-menu");
// const frame = document.body;

menuTriggers.forEach((menuTrigger) => {
  menuTrigger.addEventListener("click", () => {
    menu.classList.toggle("is-visible");
    $frame.classList.toggle("scroll-lock");
  });
});

// menuTriggerOpen.addEventListener("click", () => {
//   menu.classList.toggle("is-visible");
//   // if (menu.classList.contains("is-visible")) {
//   // menu.classList.remove("animOut");
//   // menu.classList.add("animIn");
//   // console.log("chips");
//   // }
// });

// menuTriggerCross.addEventListener("click", () => {
//   menu.classList.remove("is-visible");
//   // if (menu.classList.contains("animIn")) {
//   //   menu.classList.remove("animIn");
//   //   menu.classList.add("animOut");
//   frame.classList.remove("scroll-lock");
//   //   console.log("pas chips");
//   // }
// });

// boxes.forEach((box) => {
//   gsap.from(box, {
//     scrollTrigger: {
//       trigger: box,
//     },
//     opacity: 0,
//     duration: 1,
//   });
// });
